import React, { Component } from "react";
import { Pagination } from "antd";
import "./index.less";

class PaginationView extends Component {
  showTotal = total => {
    const { current, pageSize, hasCurrent = true } = this.props;
    const totalPage = Math.ceil(total / pageSize);
    return hasCurrent ? `第${current}页 | 共${totalPage}页 / 共${total}条记录` : `共${totalPage}页 / 共${total}条记录`;
  };
  onChange = (page, size) => {
    // const { total, pageSize, current } = this.props;
    this.props.onChange(page, size);
  };
  render() {
    const { total, pageSize, current, pageSizeOptions, onChange, simpleMode, ...rest } = this.props;
    if (!total || Number(total) === 0) {
      return null;
    }
    return (
      <Pagination
        className="pagination-layout"
        style={{ textAlign: "center" }}
        hideOnSinglePage={false}
        total={total}
        pageSize={pageSize}
        current={current}
        onShowSizeChange={this.onChange}
        onChange={this.onChange}
        showTotal={simpleMode !== true ? this.showTotal : null}
        pageSizeOptions={pageSizeOptions || ["10", "20", "50", "100", "200"]}
        showSizeChanger
        showQuickJumper
        {...rest}
      />
    );
  }
}

export default PaginationView;
